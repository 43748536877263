
import { ref, defineComponent, onMounted, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


interface TrazabilidadDetails {
  reservaId: string|string[];
  trazabilidadId: string | undefined | null;
  esInicio:boolean,
  kilometrosActuales: number | undefined | null;
  nivelCombustible: string | undefined | null;
  limpieza: string | undefined | null;
  imagenKilometros: string | undefined | null;
  imagenCombustible: string | undefined | null;
  imagenLimpieza: string | undefined | null;
  imagenes: Array<any> | undefined | null;
}

export default defineComponent({
  name: "dashboard",
  components: {
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const submitButton1 = ref<HTMLElement | null>(null);
    const imagenes = ref<any>();
    const imagen_kilometros = ref<File>();
    const imagen_combustible = ref<File>();
    const imagen_limpieza = ref<File>();
    const visibleBottom = ref(false);
    const snapshotUrl = ref('');
    const snapshotBlob = ref();
    const type = ref('');
    const id = route.params.id;
    console.log('id ' + route.params.id);
    const resolution = ref({ width: 0, height: 0  });
    const trazabilidadValidator = Yup.object().shape({
      kilometrosActuales: Yup.string().required("Es obligatorio").label("Kilometros"),
      nivelCombustible: Yup.string().required("Es obligatorio").label("Combustible"),
      limpieza: Yup.string().required("Es obligatorio").label("Limpieza"),
      //fechaDevolucion: Yup.date().required("Es obligatorio").label("Fecha de devolucion"),
      //promocion: Yup.string().required("Es obligatorio").label("Promocion"),
      //puntos: Yup.number().required("Es obligatorio").label("Puntos"),
    });
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerReserva();
      window.addEventListener('resize', handleResize);
      handleResize();
    });
    const trazabilidadDetails = ref<TrazabilidadDetails>({
      reservaId:id, 
      trazabilidadId:null,
      kilometrosActuales: null,
      nivelCombustible: "",
      limpieza: "",
      imagenKilometros: null,
      imagenCombustible: "",
      imagenLimpieza: "",
      imagenes:null,
      esInicio:false
    });
    const handleResize = () => {
        resolution.value.width = window.innerWidth;
        resolution.value.height = window.innerHeight;
    };
    const saveChanges = () => {
      if (submitButton1.value) {
        console.log("submitButton1");
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        
        let formData = new FormData();
        if(imagen_kilometros.value)
          formData.append("kilometros", imagen_kilometros.value);
        if(imagen_combustible.value)
          formData.append("combustible", imagen_combustible.value);
        if(imagen_limpieza.value)
          formData.append("limpieza", imagen_limpieza.value);
        imagenes.value?.forEach((imagen) => {
            formData.append("galeria", imagen);
        });
        trazabilidadDetails.value.reservaId = route.params.id;
        formData.append("trazabilidad", JSON.stringify(trazabilidadDetails.value));

        store
          .dispatch(Actions.INS_TRAZABILIDAD, {
            reservaId: id,
            formData,
          })
          .then(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
            router.push({ name: "ride" });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getReservaErrors);
            Swal.fire({
              text: store.getters.getReservaErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    };
    
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const currentReserva = computed(() => {
      return store.getters.currentReserva;
    });
    const sucursales = computed(() => {
      return store.getters.allSucursales;
    });
    const obtenerReserva = () => {
      store
        .dispatch(Actions.GET_TRAZABILIDAD_TERMINO, id)
        .then(() => {
          loading.value = false;
          const trazabilidad = store.getters.currentTrazabilidad;
          trazabilidadDetails.value = {
            reservaId: trazabilidad.reservaId,
            trazabilidadId:trazabilidad.trazabilidadId,
            kilometrosActuales:trazabilidad.kilometrosActuales,
            nivelCombustible: trazabilidad.nivelCombustible,
            limpieza: trazabilidad.limpieza,
            imagenKilometros: trazabilidad.imagenKilometros,
            imagenCombustible: trazabilidad.imagenCombustible,
            imagenLimpieza: trazabilidad.imagenLimpieza,
            imagenes: trazabilidad.imagenes,
            esInicio:true
          };
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getReservaErrors);
        });
    };
    

    const loading = ref(true);
    return {
      loading,
      currentUser,
      sucursales,
      submitButton1,
      saveChanges,
      trazabilidadDetails,
      trazabilidadValidator,
      currentReserva,visibleBottom,
      snapshotUrl,id,snapshotBlob,imagen_kilometros,imagen_combustible,imagen_limpieza,
           resolution
    };
  },
});
